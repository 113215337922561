import {
  computed,
} from '@vue/composition-api'
import state from '../state'
import api from '../api'
import useToast from '@/utils/toast'
import useErroReason from './useErroReason'
import useFetch from './useFetch'

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    turnOnModal: turnOnErrorReasonModal,
  } = useErroReason()

  const {
    getTargetList,
    getTimelineList,
  } = useFetch()

  const turnOnSidebar = () => {
    state.addTargetSidebar.targetNameMultiText = null
    state.addTargetSidebar.isVisible = true
  }

  const turnOffSidebar = () => {
    state.addTargetSidebar.isVisible = false
  }

  const targetNameList = computed(() => {
    if (!state.addTargetSidebar.targetNameMultiText) {
      return []
    }
    return state.addTargetSidebar.targetNameMultiText.split('\n').filter(e => Boolean(e))
  })

  const isValid = computed(() => targetNameList.value.length > 0)

  const addTargetList = () => {
    state.addTargetSidebar.isSaving = true
    return api.addTargetList({
      group_idx: state.idx,
      target_name_list: targetNameList.value,
    }).then(() => {
      makeToast('primary', '타겟리스트를 추가하는데 성공했습니다')
      getTargetList()
      getTimelineList()
    }).catch(err => {
      makeToast('danger', '타겟리스트를 추가하는데 실패했습니다')
      turnOnErrorReasonModal(err)
    }).finally(() => {
      state.addTargetSidebar.isVisible = false
    })
  }

  return {
    isValid,
    turnOnSidebar,
    turnOffSidebar,
    addTargetList,
  }
}

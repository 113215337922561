<template>
  <TagbySidebarV2
    title="전환율 엑셀 다운로드"
    :isVisible="state.downloadConversionExcelSidebar.isVisible"
    :isSaving="state.downloadConversionExcelSidebar.isDownloading"
    :isValid="isValid"
    @hidden="turnOffSidebar"
    @ok="downloadConversionExcel"
  >
    <BFormGroup label="DM전송기간">
      <TagbyDateRangeInputV3 v-model="state.downloadConversionExcelSidebar.sentAtRange" />
    </BFormGroup>

    <BFormGroup label="기여기간">
      <BFormInput
        v-model="state.downloadConversionExcelSidebar.conversionLimitDays"
        type="number"
        number
      />
    </BFormGroup>
  </TagbySidebarV2>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import {
  BFormGroup,
  BFormInput,
} from "bootstrap-vue"
import TagbyDateRangeInputV3 from "@/components/TagbyDateRangeInputV3.vue"
import TagbySidebarV2 from "@/components/TagbySidebarV2.vue"
import state from "../../state"
import useDownloadConversionExcel from "../../hooks/useDownloadConversionExcel"

export default defineComponent({
  components: {
    BFormGroup,
    BFormInput,
    TagbySidebarV2,
    TagbyDateRangeInputV3,
  },
  setup() {
    const {
      turnOffSidebar,
      downloadConversionExcel,
      isValid,
    } = useDownloadConversionExcel()

    return {
      state,
      turnOffSidebar,
      downloadConversionExcel,
      isValid,
    }
  },
})
</script>

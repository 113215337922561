<template>
  <TagbyActionCardV2>
    <CreateGroupButton />
    <UpdateGroupButton />
    <CreateMemoButton />
    <AddTargetButton />
    <!-- <TestButton /> -->
    <DownloadConversionExcel />
  </TagbyActionCardV2>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import TagbyActionCardV2 from "@/components/TagbyActionCardV2.vue"
import CreateGroupButton from "./CreateGroupButton.vue"
import UpdateGroupButton from "./UpdateGroupButton.vue"
import CreateMemoButton from "./CreateMemoButton.vue"
import AddTargetButton from "./AddTargetButton.vue"
import TestButton from "./TestButton.vue"
import DownloadConversionExcel from "./DownloadConversionExcel.vue"

export default defineComponent({
  components: {
    TagbyActionCardV2,
    CreateGroupButton,
    UpdateGroupButton,
    CreateMemoButton,
    AddTargetButton,
    TestButton,
    DownloadConversionExcel,
  },
  setup() {

  },
})
</script>

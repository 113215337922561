import {
  computed,
} from "@vue/composition-api"
import moment from "moment"
import state from "../state"
import api from "../api"
import useToast from "@/utils/toast"
import useErroReason from "./useErroReason"
import {
  any,
} from "@/utils/value"
import downloadFile from "@/utils/downloadFile"

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    turnOnModal: turnOnErrorReasonModal,
  } = useErroReason()

  const turnOnSidebar = () => {
    state.downloadConversionExcelSidebar.isVisible = true
  }

  const turnOffSidebar = () => {
    state.downloadConversionExcelSidebar.isVisible = false
  }

  const downloadConversionExcel = () => {
    state.downloadConversionExcelSidebar.isDownloading = true
    return api.downloadConversionExcel({
      group_idx: state.idx,
      sent_at_range: state.downloadConversionExcelSidebar.sentAtRange,
      conversion_limit_days: state.downloadConversionExcelSidebar.conversionLimitDays,
    }).then(response => {
      makeToast("primary", "다운로드에 성공했습니다.")
      const fileName = `dm_conversion_${moment().format("YYYYMMDDHHmmss")}.xlsx`
      const file = new File([response.data], fileName, {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
      const url = window.URL.createObjectURL(file)
      downloadFile(url, fileName)
    }).catch(err => {
      makeToast("danger", "다운로드에 실패했습니다.")
      turnOnErrorReasonModal(err)
    }).finally(() => {
      state.downloadConversionExcelSidebar.isDownloading = false
    })
  }

  const isValid = computed(() => {
    if (!state.downloadConversionExcelSidebar.conversionLimitDays) {
      return false
    }
    if (!state.downloadConversionExcelSidebar.sentAtRange) {
      return false
    }
    if (!any(state.downloadConversionExcelSidebar.sentAtRange)) {
      return false
    }
    return true
  })

  return {
    turnOnSidebar,
    turnOffSidebar,
    downloadConversionExcel,
    isValid,
  }
}

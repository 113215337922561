import {
  computed,
} from '@vue/composition-api'
import moment from 'moment'
import router from '@/router'
import state from '../state'
import api from '../api'
import useToast from '@/utils/toast'
import useErroReason from './useErroReason'
import {
  all,
} from '@/utils/value'

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    turnOnModal: turnOnErrorReasonModal,
  } = useErroReason()

  const getFormState = value => {
    if (value == null || value === '') {
      return false
    }
    if (value instanceof Array && value.length === 0) {
      return false
    }
    return true
  }

  const isValid = computed(() => {
    const fields = []
    const validationList = fields.map(f => getFormState(state[f]))
    return all(validationList)
  })

  const getHourMinute = value => {
    if (!value) {
      return null
    }
    const [hour, minute, seconds] = value.split(':')
    return [hour, minute].join(':')
  }

  const createGroup = () => {
    state.isSaving = true
    return api.createGroup({
      name: state.name,
      state: state.state,
      sender_list: state.sender_list,
      message_template: state.message_template,
      utc_offset_minutes: moment().utcOffset(),
      available_start_time: getHourMinute(state.available_start_time),
      available_end_time: getHourMinute(state.available_end_time),
    }).then(() => {
      makeToast('primary', '메시지그룹을 저장하는데 성공했습니다')
      router.push({ name: 'message-dm-group-list' })
    }).catch(err => {
      makeToast('danger', '메시지그룹을 저장하는데 실패했습니다')
      turnOnErrorReasonModal(err)
    }).finally(() => {
      state.isSaving = false
    })
  }

  return {
    isValid,
    createGroup,
  }
}

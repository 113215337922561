<template>
  <TagbyDetailLayout :hasLoadError="state.hasLoadError">
    <template #content>
      <TagbyTabs :tabList="tabList">
        <template #tab(default)>
          <InfoCard />
        </template>

        <template #tab(target)>
          <TargetList />
        </template>
      </TagbyTabs>
    </template>

    <template #side>
      <ActionCard />
      <TagbyTimelinesV3
        v-if="!getters.isCreatingMode"
        :timelineList="state.timelineList"
      />
    </template>

    <template #hidden>
      <Sidebars />
      <Modals />
    </template>
  </TagbyDetailLayout>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { getRouterIdx } from '@/utils/router'
import TagbyTabs from '@/components/TagbyTabs.vue'
import TagbyDetailLayout from '@/components/TagbyDetailLayout.vue'
import TagbyTimelinesV3 from '@/components/TagbyTimelinesV3.vue'
import ActionCard from './components/ActionCard/Main.vue'
import InfoCard from './components/InfoCard'
import TargetList from './components/TargetList'
import Sidebars from './components/Sidebars'
import Modals from './components/Modals'
import useFetch from './hooks/useFetch'
import state from './state'
import getters from './getters'
import useState from './hooks/useState'

export default defineComponent({
  components: {
    TagbyDetailLayout,
    TagbyTabs,
    ActionCard,
    TagbyTimelinesV3,
    InfoCard,
    TargetList,
    Sidebars,
    Modals,
  },
  setup() {
    const {
      resetState,
    } = useState()
    resetState()

    state.idx = getRouterIdx()

    const {
      getAdminMe,
      getDetail,
      getTargetList,
      getTimelineList,
    } = useFetch()

    if (!getters.isCreatingMode) {
      getAdminMe()
      getDetail()
      getTargetList()
      getTimelineList()
    }

    const tabList = [
      { key: 'default', title: '기본' },
      { key: 'target', title: '타겟', visible: !getters.isCreatingMode },
    ]

    return {
      tabList,
      state,
      getters,
    }
  },
})
</script>

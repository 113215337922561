<template>
  <BRow>
    <BCol
      md="3"
      sm="4"
      cols="6"
    >
      <BFormGroup>
        <BFormSelect
          :value="fieldsId"
          :options="fieldGroupList"
          @input="$emit('selectFieldGroup', $event)"
        />
      </BFormGroup>
    </BCol>

    <BCol
      md="3"
      sm="4"
      cols="6"
    >
      <BFormGroup>
        <BFormCheckbox
          :checked="selectable"
          switch
          @input="$emit('inputSelectable', $event)"
        >
          행 선택
        </BFormCheckbox>
      </BFormGroup>
    </BCol>

    <BCol
      md="3"
      sm="4"
      cols="6"
    >
      <BDropdown
        variant="outline-primary"
        :text="`Actions(${selectedItemLength}개)`"
      >
        <span
          v-for="action in actions"
          :key="action.title"
          tabindex="0"
          class="d-inline-block w-100"
        >
          <span
            v-if="action.disabled"
            v-b-tooltip.right
            tabindex="0"
            class="d-inline-block w-100"
            :title="action.tooltip"
          >
            <BDropdownItem
              :disabled="true"
            >
              {{ action.title }}
            </BDropdownItem>
          </span>
          <span
            v-else
            class="d-inline-block w-100"
          >
            <BDropdownItem
              :disabled="false"
              @click="action.func(action.args)"
            >
              {{ action.title }}
            </BDropdownItem>
          </span>
        </span>
      </BDropdown>
    </BCol>

    <!-- <BCol
      md="3"
      sm="4"
      cols="6"
    >
      <div class="text-danger">
        * cmd(ctrl): 링크 이동
      </div>
      <div class="text-danger">
        * option(alt): 텍스트 복사
      </div>
    </BCol> -->
  </BRow>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormCheckbox,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  VBTooltip,
} from 'bootstrap-vue'

export default defineComponent({
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormCheckbox,
    BFormSelect,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    selectable: {
      type: Boolean,
    },
    fieldGroupList: {
      type: Array,
    },
    fieldsId: {
      type: String,
    },
    selectedItemLength: {
      type: Number,
    },
    actions: {
      type: Array,
    },
  },
  setup() {
  },
})
</script>

export default () => ({
  hasLoadError: false,
  adminPermission: null,
  idx: null,
  member_type: null,
  member_idx: null,
  member_name: null,
  state: "ACTIVE",
  name: null,
  sender_list: null,
  message_template: null,
  utc_offset_minutes: null,
  available_start_time: null,
  available_end_time: null,
  created_at: null,
  isSaving: false,
  timelineList: [],
  errorReasonModal: {
    isVisible: false,
    errorReason: null,
  },
  createMemoSidebar: {
    isVisible: false,
    isSaving: false,
    text: null,
  },
  targetList: [],
  addTargetSidebar: {
    isVisible: false,
    targetNameMultiText: null,
    isSaving: false,
  },
  updateTargetSidebar: {
    isVisible: false,
    targetIdx: null,
    targetName: null,
    targetState: null,
  },
  deleteTargetModal: {
    isVisible: false,
    targetIdx: null,
  },
  downloadConversionExcelSidebar: {
    isVisible: false,
    isDownloading: false,
    conversionLimitDays: 7,
    sentAtRange: null,
  },
})

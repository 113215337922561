<template>
  <TagbyActionButtonV2
    v-if="!getters.isCreatingMode"
    variant="outline-success"
    text="전환율 엑셀 다운로드"
    :isValid="true"
    @click="turnOnSidebar"
  />
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import TagbyActionButtonV2 from "@/components/TagbyActionButtonV2.vue"
import getters from "../../getters"
import useDownloadConversionExcel from "../../hooks/useDownloadConversionExcel"

export default defineComponent({
  components: {
    TagbyActionButtonV2,
  },
  setup() {
    const {
      turnOnSidebar,
    } = useDownloadConversionExcel()

    return {
      getters,
      turnOnSidebar,
    }
  },
})
</script>

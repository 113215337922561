<template>
  <BFormInput
    v-if="disabled"
    :value="parsedValue"
    disabled
  />
  <FlatPickr
    v-else
    :value="parsedValue"
    class="form-control"
    :config="{ mode: 'range'}"
    :disabled="disabled"
    @input="$emit('input', getDateArray($event))"
  />
</template>

<script>
import { defineComponent, computed } from "@vue/composition-api"
import {
  BFormInput,
} from "bootstrap-vue"
import FlatPickr from "vue-flatpickr-component"
import useResolveValue from "@/utils/resolveValue"

export default defineComponent({
  components: {
    BFormInput,
    FlatPickr,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
    },
  },
  setup(props) {
    const {
      resolveDate,
      resolveUtcStartDateTimeTz,
      resolveUtcEndDateTimeTz,
    } = useResolveValue()

    const parsedValue = computed(() => {
      const startAt = props.value?.[0]
      const endAt = props.value?.[1]
      const localStartDate = resolveDate(startAt)
      const localEndDate = resolveDate(endAt)
      if (localStartDate == null) {
        return null
      }
      if (localEndDate == null || localStartDate === localEndDate) {
        return localStartDate
      }
      return `${localStartDate} to ${localEndDate}`
    })

    const addUtcTimezone = dateString => {
      if (dateString == null) {
        return null
      }
      return `${dateString}`
    }

    const getDateArray = dateString => {
      const splitedDate = dateString.split(" to ")
      const startAt = resolveUtcStartDateTimeTz(splitedDate[0])
      const startAtZ = addUtcTimezone(startAt)
      if (splitedDate[1] == null) {
        const endAt = resolveUtcEndDateTimeTz(splitedDate[0])
        const endAtZ = addUtcTimezone(endAt)
        return [startAtZ, endAtZ]
      }
      const endAt = resolveUtcEndDateTimeTz(splitedDate[1])
      const endAtZ = addUtcTimezone(endAt)
      return [startAtZ, endAtZ]
    }

    return {
      parsedValue,
      getDateArray,
    }
  },
})
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

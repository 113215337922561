<template>
  <TagbySidebarWithButton
    title="타겟 목록 추가"
    :visible="state.addTargetSidebar.isVisible"
    :isSaving="state.addTargetSidebar.isSaving"
    :isValid="isValid"
    @ok="addTargetList"
    @cancel="turnOffSidebar"
    @hidden="turnOffSidebar"
  >
    <BFormGroup label="타겟 유저네임 목록">
      <BFormTextarea
        v-model="state.addTargetSidebar.targetNameMultiText"
        rows="5"
        placeholder="유저네임 목록을 엔터로 구분해서 입력해주세요."
      />
    </BFormGroup>
  </TagbySidebarWithButton>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BFormGroup,
  BFormTextarea,
} from 'bootstrap-vue'
import TagbySidebarWithButton from '@/components/TagbySidebarWithButton.vue'
import state from '../../state'
import useAddTarget from '../../hooks/useAddTarget'

export default defineComponent({
  components: {
    BFormGroup,
    BFormTextarea,
    TagbySidebarWithButton,
  },
  setup() {
    const {
      isValid,
      addTargetList,
      turnOffSidebar,
    } = useAddTarget()

    return {
      state,
      isValid,
      addTargetList,
      turnOffSidebar,
    }
  },
})
</script>

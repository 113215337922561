import state from '../state'
import api from '../api'
import useToast from '@/utils/toast'
import useErroReason from './useErroReason'
import useFetch from './useFetch'

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    turnOnModal: turnOnErrorReasonModal,
  } = useErroReason()

  const {
    getTimelineList,
  } = useFetch()

  const turnOnSidebar = () => {
    state.createMemoSidebar.text = null
    state.createMemoSidebar.isVisible = true
  }
  const turnOffSidebar = () => {
    state.createMemoSidebar.isVisible = false
  }

  const createMemo = () => {
    state.createMemoSidebar.isSaving = true
    return api.createMemo({
      info_table: 'insta_dm_group_info',
      info_idx: state.idx,
      text: state.createMemoSidebar.text,
    }).then(() => {
      makeToast('primary', '메모 생성에 성공했습니다')
      getTimelineList()
      turnOffSidebar()
    }).catch(err => {
      makeToast('danger', '메모 생성에 실패했습니다')
      turnOnErrorReasonModal(err)
    }).finally(() => {
      state.createMemoSidebar.isSaving = false
    })
  }

  return {
    turnOnSidebar,
    turnOffSidebar,
    createMemo,
  }
}

<template>
  <BCard no-body>
    <BTable
      ref="tgTableRef"
      :items="items"
      :fields="fields"
      show-empty
      small
      responsive
      :selectable="selectable"
      select-mode="multi"
      :current-page="currentPage"
      :per-page="perPage"
    >
      <template #head(checkbox)>
        <BFormCheckbox
          @input="inputTableCheckbox"
        />
      </template>

      <template #cell(checkbox)="data">
        <BFormCheckbox
          :checked="data.rowSelected"
          @input="inputRowCheckbox(data.item.idx, $event)"
        />
      </template>

      <template #cell(sent_at)="data">
        <TagbyDatetimeColumn :value="data.value" />
      </template>

      <template #cell(created_at)="data">
        <TagbyDatetimeColumn :value="data.value" />
      </template>
    </BTable>
    <BRow>
      <BCol
        cols="6"
        class="d-flex align-items-center justify-content-star pl-2"
      >
        <span class="text-muted">Showing {{ fromRow }} to {{ toRow }} of {{ ofRow }} entries</span>
      </BCol>
      <BCol
        :cols="6"
        class="d-flex align-items-center justify-content-end"
      >
        <BPagination
          v-model="currentPage"
          :total-rows="ofRow"
          :per-page="perPage"
          align="center"
          size="sm"
          first-number
          last-number
        />
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import {
  defineComponent, ref, computed,
} from '@vue/composition-api'
import {
  BCard,
  BTable,
  BRow,
  BCol,
  BPagination,
  BLink,
  BAvatarGroup,
  BAvatar,
  BFormCheckbox,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import ComptIdxColumn from '@/components/ComptIdxColumn.vue'
import TagbyDatetimeColumn from '@/components/TagbyDatetimeColumn.vue'
import TagbyDateColumn from '@/components/TagbyDateColumn.vue'
import TagbySnsColumn from '@/components/TagbySnsColumn.vue'
import TagbySnsAvatar from '@/components/TagbySnsAvatar.vue'
import TagbyConditionalLink from '@/components/TagbyConditionalLink.vue'
import useResolveValue from '@/utils/resolveValue'
import TagbyTableAction from '@/components/TagbyTableAction.vue'
import TagbyTableActionItem from '@/components/TagbyTableActionItem.vue'

export default defineComponent({
  components: {
    BCard,
    BTable,
    ComptIdxColumn,
    TagbyDatetimeColumn,
    TagbySnsColumn,
    TagbyConditionalLink,
    TagbySnsAvatar,
    BRow,
    BCol,
    BPagination,
    BLink,
    BAvatarGroup,
    BAvatar,
    TagbyTableAction,
    TagbyTableActionItem,
    BFormCheckbox,
    TagbyDateColumn,
    BDropdown,
    BDropdownItem,
  },
  props: {
    selectable: {
      type: Boolean,
    },
    fields: {
      type: Array,
    },
    items: {
      type: Array,
    },
  },
  setup(props) {
    const {
      resolveMoney,
    } = useResolveValue()

    const tgTableRef = ref()

    const inputRowCheckbox = (itemIdx, checked) => {
      const tableRef = tgTableRef.value
      if (tableRef == null) return
      const i = tableRef.items.findIndex(item => item.idx === itemIdx)
      if (checked) {
        tableRef.selectRow(i)
      } else {
        tableRef.unselectRow(i)
      }
    }

    const inputTableCheckbox = checked => {
      const tableRef = tgTableRef.value
      if (tableRef == null) return
      if (checked) {
        tableRef.selectAllRows()
      } else {
        tableRef.clearSelected()
      }
    }

    const perPage = ref(10)
    const currentPage = ref(1)

    const fromRow = computed(() => (currentPage.value - 1) * perPage.value + 1)
    const ofRow = computed(() => props.items?.length ?? 0)
    const toRow = computed(() => {
      const endRow = fromRow.value + perPage.value
      if (endRow < ofRow.value) {
        return endRow
      }
      return ofRow.value
    })

    const getSelectedItems = () => {
      const tableRef = tgTableRef.value
      if (tableRef == null) {
        return 0
      }
      const selectedItemList = tableRef.items.filter((_, i) => tableRef.isRowSelected(i))
      return selectedItemList
    }

    const getSelectedItemLength = () => getSelectedItems()?.length

    return {
      resolveMoney,
      tgTableRef,
      inputRowCheckbox,
      inputTableCheckbox,
      perPage,
      currentPage,
      fromRow,
      ofRow,
      toRow,
      getSelectedItems,
      getSelectedItemLength,
    }
  },
})
</script>

<template>
  <div>
    <TgFilter
      :searchText="searchText"
      :stateFilter="stateFilter"
      @inputSearchText="inputSearchText"
      @inputState="inputStateFilter"
    />
    <TgButtons
      :selectable="selectable"
      :fieldGroupList="fieldGroupList"
      :fieldsId="fieldsId"
      :selectedItemLength="selectedItemLength"
      :actions="actions"
      @inputSelectable="inputSelectable"
      @selectFieldGroup="selectFieldGroup"
    />
    <TgTable
      ref="tgTableRef"
      :selectable="selectable"
      :fields="fields"
      :items="items"
    />
  </div>
</template>

<script>
import { defineComponent, ref, computed } from '@vue/composition-api'
import {
  BCard,
  BTable,
} from 'bootstrap-vue'
import TgFilter from './TgFilter.vue'
import TgTable from './TgTable.vue'
import TgButtons from './TgButtons.vue'
import state from '../../state'

export default defineComponent({
  components: {
    BCard,
    BTable,
    TgFilter,
    TgTable,
    TgButtons,
  },
  setup() {
    const selectable = ref(false)
    const fieldGroupListTemplate = [
      {
        text: '타겟정보',
        value: 'target',
        fields: [
          { key: 'idx', label: 'idx' },
          { key: 'sender_name', label: '사용계정' },
          { key: 'target_name', label: '타겟유저' },
          { key: 'state', label: '상태' },
          { key: 'fail_reason', label: '실패사유' },
          { key: 'sent_at', label: '전송일시' },
          { key: 'created_at', label: '추가일시' },
        ],
      },
    ]

    const fieldGroupList = computed(() => {
      if (!selectable.value) {
        return fieldGroupListTemplate
      }
      return fieldGroupListTemplate.map(fieldGroup => ({
        text: fieldGroup.text,
        value: fieldGroup.value,
        fields: [
          { key: 'checkbox' },
          ...fieldGroup.fields,
        ],
      }))
    })

    const fieldsId = ref(fieldGroupList.value[0].value)
    const fields = computed(() => fieldGroupList.value.find(fieldGroup => fieldGroup.value === fieldsId.value).fields)

    const searchText = ref()
    const stateFilter = ref([])

    const tgTableRef = ref()

    const selectedItemLength = computed(() => tgTableRef.value?.getSelectedItemLength() ?? 0)

    const items = computed(() => state.targetList.filter(e => {
      if (!searchText.value) {
        return true
      }
      return e.sender_name?.includes(searchText.value) || e.target_name.includes(searchText.value)
    }).filter(e => {
      if (!stateFilter.value.length) {
        return true
      }
      return stateFilter.value.includes(e.state)
    }))

    const inputSearchText = value => {
      searchText.value = value
    }
    const inputStateFilter = value => {
      stateFilter.value = value
    }
    const inputSelectable = value => {
      selectable.value = value
    }
    const selectFieldGroup = value => {
      fieldsId.value = value
    }

    const actions = computed(() => ([
      // {
      //   title: '리워드 금액 변경',
      //   disabled: !getAvailableUpdateAmount(),
      //   func: turnOnUpdateAmountSidebar,
      //   args: tgTableRef.value?.getSelectedItems(),
      //   tooltip: (
      //     '일반 사용자 소유 캠페인의 경우, 결제가 완료된 상태라면 결제금액 수정을 할 수 없습니다. '
      //     + '태거 소유 캠페인의 경우, 인플루언서에게 포인트 지급완료하기 전까지는 결제금액 수정이 가능합니다.'
      //   ),
      // },
      // {
      //   title: '리워드 결제 실행',
      //   disabled: true,
      //   func: () => {},
      //   tooltip: (
      //     '[오픈예정] 일반 사용자 소유 캠페인의 경우, 등록된 카드로 결제를 즉시 실행합니다. '
      //     + '태거 소유 캠페인의 경우, 실제 결제는 실행하지 않으며 상태만 결제완료로 변경합니다.'
      //   ),
      // },
      // {
      //   title: '리워드 결제 취소',
      //   disabled: true,
      //   func: () => {},
      //   tooltip: (
      //     '[오픈예정] 사용자의 결제된 금액을 환불하는 기능입니다. '
      //     + '지급 예정인 포인트 데이터가 있다면 취소처리하며, 정산 데이터의 상태는 "결제대기"로 수정됩니다. '
      //     + '해당 기능을 실행한 후 시간이 흐르면 자동으로 재결제가 실행되므로 "결제날짜변경"을 미리 해두셔야 합니다. '
      //     + '또는 취소이후 재결제도 원하지 않는 상태라면 "리워드 완전 취소" 기능을 이용하시길 바랍니다.'
      //   ),
      // },
      // {
      //   title: '리워드 완전 취소',
      //   disabled: true,
      //   func: () => {},
      //   tooltip: (
      //     '[오픈예정] 일반 사용자 소유 캠페인의 경우, 등록된 카드로 환불을 실행합니다. '
      //     + '태거 소유 캠페인의 경우, 실제 환불 프로세스는 진행하지 않습니다. '
      //     + '지급 예정인 포인트는 취소처리하며, 정산 데이터의 상태는 "결제취소"로 수정됩니다. '
      //   ),
      // },
      // {
      //   title: '포인트 지급 취소',
      //   disabled: true,
      //   func: () => {},
      //   tooltip: (
      //     '[오픈예정] "지급예정" 상태의 포인트를 취소처리합니다. "지급완료"가 된 포인트는 취소하지 못합니다.'
      //     + '포스팅을 "확인완료" 처리할 때만 포인트 지급이 생성되므로 만약 포스팅을 확인완료 처리할 일이 없다면, '
      //     + '따로 자동으로 인플루언서에게 포인트를 재지급하지 않습니다. '
      //     + '만약 포인트 지급 취소후에 따로 포스팅 확인완료 처리없이 포인트 지급이 필요하다면, '
      //     + '인플루언서 상페 페이지에서 "포인트 관리" 기능을 이용해주세요.'
      //   ),
      // },
      // {
      //   title: '결제 날짜 변경',
      //   disabled: true,
      //   func: () => {},
      //   tooltip: (
      //     '[오픈예정]'
      //   ),
      // },
    ]))

    return {
      tgTableRef,
      selectable,
      fieldGroupList,
      fieldsId,
      searchText,
      stateFilter,
      fields,
      items,
      selectedItemLength,
      inputSearchText,
      inputStateFilter,
      inputSelectable,
      selectFieldGroup,
      actions,
    }
  },
})
</script>

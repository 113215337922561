<template>
  <BCard>
    <BRow>
      <BCol
        v-if="!getters.isCreatingMode"
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="IDX">
          <BFormInput
            v-model="state.idx"
            readonly
          />
        </BFormGroup>
      </BCol>

      <BCol
        v-if="!getters.isCreatingMode"
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="MEMBER_INFO">
          <BFormInput
            :value="memberInfo"
            readonly
          />
        </BFormGroup>
      </BCol>

      <BCol
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="그룹이름">
          <BFormInput
            v-model="state.name"
            :state="getFormState(state.name)"
          />
        </BFormGroup>
      </BCol>

      <BCol
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="상태">
          <BFormSelect
            v-model="state.state"
            :options="stateOptions"
            :state="getFormState(state.state)"
          />
        </BFormGroup>
      </BCol>

      <BCol
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="전송시작시간">
          <BFormTimepicker
            v-model="state.available_start_time"
            reset-button
            label-reset-button="초기화"
            label-close-button="닫기"
            :state="getFormState(state.available_start_time)"
          />
        </BFormGroup>
      </BCol>

      <BCol
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="전송종료시간">
          <BFormTimepicker
            v-model="state.available_end_time"
            reset-button
            label-reset-button="초기화"
            label-close-button="닫기"
            :state="getFormState(state.available_end_time)"
          />
        </BFormGroup>
      </BCol>

      <BCol
        v-if="!getters.isCreatingMode"
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="생성일">
          <TagbyDatetimeInputV3
            :value="state.created_at"
            :config="{
              enableTime: true,
              dateFormat: 'Y-m-d H:i',
            }"
            :disabled="true"
          />
        </BFormGroup>
      </BCol>
    </BRow>

    <hr>

    <BRow>
      <BCol cols="12">
        <BFormGroup
          label="사용할 유저네임 리스트"
          :state="getFormState(state.sender_list)"
        >
          <TagbySelect
            v-model="state.sender_list"
            :options="senderNameOptions"
            :multiple="true"
            :state="getFormState(state.sender_list)"
          />
          <div class="text-danger">
            추가하려는 계정이 목록에 없다면 강하일 매니저에게 요청 부탁드립니다.
          </div>
        </BFormGroup>
      </BCol>
    </BRow>

    <hr>

    <BRow>
      <BCol cols="12">
        <BFormGroup label="메시지 템플릿">
          <BFormTextarea
            v-model="state.message_template"
            :state="getFormState(state.message_template)"
          />
        </BFormGroup>
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import {
  defineComponent,
  computed,
} from "@vue/composition-api"
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormDatepicker,
  BFormCheckbox,
  BFormTags,
  BFormTextarea,
  BFormTimepicker,
  BFormSelect,
} from "bootstrap-vue"
import TagbyDatetimeInputV3 from "@/components/TagbyDatetimeInputV3.vue"
import TagbySelect from "@/components/TagbySelect.vue"
import state from "../../state"
import getters from "../../getters"

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    TagbyDatetimeInputV3,
    BFormCheckbox,
    BFormTags,
    BFormTextarea,
    BFormTimepicker,
    BFormSelect,
    TagbySelect,
  },
  setup() {
    const stateOptions = [
      "ACTIVE",
      "STOP",
      "INACTIVE",
    ]
    const test = value => {
      console.log(value)
    }
    const getFormState = value => {
      if (value == null || value === "") {
        return false
      }
      if (value instanceof Array && value.length === 0) {
        return false
      }
      return null
    }
    const senderNameOptions = [
      "tagby.hail.02",
      "tagby.hail.03",
      "tagby.hail.04",
      "tagby.hail.05",
      "tagby.hail.06",
      "tagby_hail",
      "tagby_jime",
      "tagby_jim",
      "tagby_mili",
      "tagby_milo",
      "tagby_zeze",
      "tagby_wis",
      "tagby_russell",
      "tagby_javier",
      "tagby_duane",
      "tagby_harold",
      "tagby_curtis",
      "tagby_john",
      "tagby_saas",
      "tagby_jays",
    ]
    const memberInfo = computed(() => `${state.member_type}:${state.member_idx}:${state.member_name}`)
    return {
      state,
      getters,
      stateOptions,
      senderNameOptions,
      memberInfo,
      test,
      getFormState,
    }
  },
})
</script>

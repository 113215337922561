import axiosFrame from "@/utils/axiosFrame"

export default {
  getAdminMe: axiosFrame("get", "/manage/admin/me/", null),
  getDetail: axiosFrame("get", "/manage/dm-group/detail/", null),
  getTimelineList: axiosFrame("get", "/manage/history/timeline-list/", null),
  createMemo: axiosFrame("post", "/manage/memo/create-memo/", null),
  createGroup: axiosFrame("post", "/manage/dm-group/create-group/", null),
  updateGroup: axiosFrame("post", "/manage/dm-group/update-group/", null),
  getTargetList: axiosFrame("get", "/manage/dm-group/target-list/", null),
  addTargetList: axiosFrame("post", "/manage/dm-group/add-target-list/", null),
  updateTarget: axiosFrame("post", "/manage/dm-group/update-target/", null),
  deleteTarget: axiosFrame("post", "/manage/dm-group/delete-target/", null),
  downloadConversionExcel: axiosFrame("post", "/manage/dm-group/download-conversion-excel/", "blob"),
}

<template>
  <div>
    <CreateMemoSidebar />
    <AddTargetListSidebar />
    <DownloadConversionExcel />
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import CreateMemoSidebar from "./CreateMemoSidebar.vue"
import AddTargetListSidebar from "./AddTargetListSidebar.vue"
import DownloadConversionExcel from "./DownloadConversionExcel.vue"

export default defineComponent({
  components: {
    CreateMemoSidebar,
    AddTargetListSidebar,
    DownloadConversionExcel,
  },
  setup() {

  },
})
</script>
